import { instance } from '~/utils/api/api';
import { DatePicker, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { BUTTON_STYLES, Button, RadioInput } from '~/ui';
import styles from './XlsReportAccommodationPopoverContent.module.scss';
import { useContext, useState } from 'react';
import { PopoverContext } from '~/components/XlsUploadFilePopover/XlsUploadFilePopover';
import { downloadFile } from '~/utils/downloadFile';
import { useTranslation } from 'react-i18next';

const XlsReportAccommodationPopoverContent = () => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [typeReport, setTypeReport] = useState<string>('1');
  const { setIsPopoverVisible } = useContext(PopoverContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const onClosePopup = () => {
    setStartDate(null);
    setEndDate(null);
    setIsPopoverVisible(false);
  };

  const downloadFileReport = async () => {
    setIsLoading(true);
    let typeReportPath: string;
    let typeReportName: string;

    if (typeReport === '1') {
      typeReportPath = 'analytics-accommodation-all';
      typeReportName = 'analytics-accommodation';
    } else if (typeReport === '2') {
      typeReportPath = 'analytics-accommodation-statistics';
      typeReportName = 'analytics-accommodation-details';
    } else {
      return;
    }

    const response = await instance.get(`admin/${typeReportPath}`, {
      params: {
        start_date:
          typeReport === '2' ? startDate?.format('YYYY-MM-DD') : startDate,
        end_date: typeReport === '2' ? endDate?.format('YYYY-MM-DD') : endDate,
      },
      responseType: 'blob',
    });

    downloadFile(response.data, 'text/xlsx', `${typeReportName}.xlsx`);
    setIsLoading(false);
  };

  const disabledSendReport = !startDate || !endDate;

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs().startOf('day');
  };

  return (
    <div className={styles.formWrapper}>
      <span className={styles.formTitle}>{t('analytics_on_placements')}</span>
      <div className={styles.radioBtnTypeReportWrapper}>
        <RadioInput
          label={t('brief_report')}
          name="type_report"
          value="1"
          onChange={() => setTypeReport('1')}
          checkValue={typeReport}
          disabled={false}
        />
        <RadioInput
          label={t('full_report')}
          name="type_report"
          value="2"
          onChange={() => setTypeReport('2')}
          checkValue={typeReport}
          disabled={false}
        />
      </div>
      <div className={styles.dateWrapper}>
        <div className={styles.labelWrapper}>
          <span className={styles.labelText}>
            {t('date')}
            <span className={styles.labelStar}>*</span>
          </span>
          <Space direction="vertical" className="ant-space">
            <DatePicker.RangePicker
              className="datepicker"
              format="DD.MM.YYYY"
              value={[startDate, endDate]}
              onChange={dates => {
                if (dates) {
                  setStartDate(dates[0]);
                  setEndDate(dates[1]);
                } else {
                  setStartDate(null);
                  setEndDate(null);
                }
              }}
              placeholder={[t('date_from'), t('date_by')]}
              disabledDate={disabledDate}
            />
          </Space>
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <Button
          onClick={onClosePopup}
          className={styles.contentBtn}
          text={t('cancel')}
          disabled={false}
          buttonStyle={BUTTON_STYLES.EMPTY}
        />
        <Button
          onClick={downloadFileReport}
          type="submit"
          text={t('download_report')}
          className={styles.sendBtn}
          disabled={disabledSendReport || isLoading}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default XlsReportAccommodationPopoverContent;
