import UserMenu from '~/components/UserMenu/UserMenu';
import HeaderBar from './HeaderBar/HeaderBar';
import styles from './header.module.scss';

const Header = (): JSX.Element => {
  return (
    <header className={styles.header}>
      <div className={styles.header__container}>
        <HeaderBar />
        <div className={styles.header__container_right}>
          <UserMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
