import { useEffect, useState } from 'react';
import styles from './CompleteQuestionnariePage.module.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { instance } from '~/utils/api/api';
import { Questionnaire } from '../Questionnaires/QuestionnairesBoard/QuestionnairesTable/QuestionnairesTable';
import { Spinner } from '~/ui';
import CompleteQuestionnariePageForm from './CompleteQuestionnariePageForm/CompleteQuestionnariePageForm';
import { useToastError } from '~/utils/useToastError';

const CompleteQuestionnariePage = () => {
  const [questionnarie, setQuestionnarie] = useState<Questionnaire | null>(
    null
  );
  const [searchParams] = useSearchParams();
  const questionnarieId = searchParams.get('questionnarieId');
  const userId = searchParams.get('userId');
  const navigate = useNavigate();
  const toastError = useToastError();

  const getQuestionnarie = async () => {
    try {
      const { data } = await instance.get(
        `user-questionnaires/${questionnarieId}` +
          (userId ? `?user_id=${userId}` : '')
      );
      setQuestionnarie(data);
    } catch (error: any) {
      if (error.response.status === 403) {
        navigate('user-error', { replace: true });
      } else {
        toastError(error);
      }
    }
  };

  useEffect(() => {
    if (!questionnarie) {
      getQuestionnarie();
    }
  }, []);

  if (!questionnarie) {
    return (
      <div className={styles.loaderContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <main className={styles.completeQuestionnariePage}>
      <h2 className={styles.title}>iFCM</h2>
      <h3 className={styles.pageDescription}>
        Оцените качество обслуживания столовой iFCM Group
      </h3>
      <CompleteQuestionnariePageForm
        questionnarie={questionnarie}
        userId={userId}
      />
    </main>
  );
};

export default CompleteQuestionnariePage;
