import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useDebounce } from '@uidotdev/usehooks';
import { Board } from '~/components/Shared/Layout/Board/Board';
import { ErrorMessage } from '~/components/Shared/ErrorMessage/ErrorMessage';
import { InputBar, Pagination, Spinner } from '~/ui';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { instance } from '~/utils/api/api';
import { OrdersTable } from './OrdersTable';
import styles from './OrdersBoard.module.scss';
import FoodExportActions from '~/components/FoodExportActions/FoodExportActions';
import { useTranslation } from 'react-i18next';
import { DatePicker, Space } from 'antd';
import Toggle from '~/components/Shared/Toggle/Toggle';
import { useToastError } from '~/utils/useToastError';

interface SelectedPage {
  selected: number;
}

interface OrdersBoardProps {
  dates: [Dayjs | null, Dayjs | null];
  isAutosendOn: boolean;
  handleDateChange: (date: [Dayjs | null, Dayjs | null]) => void;
}

export const OrdersBoard = ({
  dates,
  isAutosendOn,
  handleDateChange,
}: OrdersBoardProps): JSX.Element => {
  const { foodID } = useParams();

  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [page, setPage] = useState<number>();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const toastError = useToastError();

  const handleToggleAutosend = async () => {
    const savedIsChecked = isChecked;
    setIsChecked(!isChecked);
    try {
      await instance.post(`food-admin-autosend/${foodID}`);
    } catch (error) {
      setIsChecked(savedIsChecked);
      toastError(error);
    }
  };

  const params = {
    sort: sort.direction,
    perPage: 15,
    food_ids: [foodID],
    field: sort.field,
    query: debouncedSearchTerm,
    page,
    dates: [
      dayjs(dates[0])?.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      dayjs(dates[1])?.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    ],
  };

  const {
    data: orders,
    isError,
    error,
  } = useQuery({
    queryFn: async () => {
      const response = await instance.get(`admin/food-orders`, {
        params,
      });
      return response.data;
    },
    queryKey: ['transactions', foodID, params],
    keepPreviousData: true,
  });

  let content: React.ReactNode;

  if (orders?.data) {
    content = (
      <OrdersTable orders={orders.data} sort={sort} setSort={setSort} />
    );
  } else if (isError) {
    content = <ErrorMessage error={error} />;
  } else {
    content = <Spinner />;
  }

  useEffect(() => {
    setIsChecked(isAutosendOn);
  }, [isAutosendOn]);

  return (
    <Board>
      <div className={styles.options}>
        <div className={styles.toggleWrapper}>
          <span className={styles.toggleText}>{t('autosend')}</span>
          <Toggle isChecked={isChecked} onChange={handleToggleAutosend} />
        </div>
        <InputBar
          onChange={e => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          value={searchTerm}
          placeholder={`${t('search_by_id_nn')}..`}
          classNameInput={styles.input}
          label={`${t('search')}:`}
          star={false}
          classNameInputWrapper={styles.inputWrapper}
        />
        <FoodExportActions foodID={+foodID!} dates={dates} />
        <div className={styles.datePicker}>
          <Space direction="vertical" className="ant-space">
            <DatePicker.RangePicker
              className="datepicker"
              format="DD.MM.YYYY"
              value={dates}
              onChange={date => {
                if (date && date[0] && date[1]) {
                  handleDateChange(date);
                } else {
                  handleDateChange([dayjs(), dayjs()]);
                }
              }}
              placeholder={[t('date'), t('date')]}
            />
          </Space>
        </div>
      </div>
      {content}
      <div className={styles.pagination}>
        <TableAmountPage
          firstRow={orders?.meta?.from}
          lastRow={orders?.meta?.to}
          total={orders?.meta?.total}
        />
        <Pagination
          pageCount={1}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
    </Board>
  );
};
