import EventsBoard from '~/components/Events/EventsBoard/EventsBoard';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { useTranslation } from 'react-i18next';

import styles from './Events.module.scss';

const Events = () => {
  const { t } = useTranslation();

  return (
    <Page heading={<Heading text={t('events')} />}>
      <div className={styles.tabsWrapper}>
        <EventsBoard />
      </div>
    </Page>
  );
};

export default Events;
