import { DatePicker } from 'antd';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';
import { Button, InputBar } from '~/ui';
import { useFormik } from 'formik';
import { IUsersFilters } from '~/utils/types/userFilterTypes';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import styles from './OrderFilter.module.scss';
import cn from 'classnames';

interface IFormik {
  dates: {
    start_date: string;
    end_date: string;
  };
  cancel_orders: {
    cancel_by: { id: number; name: string; display_name: string };
    condition: { id: number; name: string; display_name: string };
    count: string;
  };
  cancel_wares: {
    condition: { id: number; name: string; display_name: string };
    count: string;
  };
}

interface IOrderProps {
  selectedUsers: number[];
  onClick: (filters: IUsersFilters) => void;
  setIsStatusUsersModalOpen: () => void;
}

export const OrderFilter = ({
  onClick,
  selectedUsers,
  setIsStatusUsersModalOpen,
}: IOrderProps) => {
  const formik = useFormik<IFormik>({
    initialValues: {
      dates: {
        start_date: '',
        end_date: '',
      },
      cancel_orders: {
        cancel_by: { id: 0, name: '', display_name: '' },
        condition: { id: 0, name: '', display_name: '' },
        count: '',
      },
      cancel_wares: {
        condition: { id: 0, name: '', display_name: '' },
        count: '',
      },
    },
    onSubmit: values => {
      const filters = {
        ...(values.cancel_orders.condition.name &&
          values.cancel_orders.count &&
          values.cancel_orders.cancel_by.name && {
            cancel_orders: {
              condition: values.cancel_orders.condition.name,
              cancel_by: values.cancel_orders.cancel_by.name,
              count: values.cancel_orders.count,
            },
          }),
        ...(values.cancel_wares.condition.name &&
          values.cancel_wares.count && {
            cancel_wares: {
              condition: values.cancel_wares.condition.name,
              count: values.cancel_wares.count,
            },
          }),
        ...(values.dates.end_date &&
          values.dates.start_date && {
            order_created_dates: {
              dates: [values.dates.start_date, values.dates.end_date],
            },
          }),
      };

      onClick(filters);
    },
  });

  return (
    <div className={styles.orderContainer}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputsWrapper}>
          <div className={styles.filtersLeft}>
            <div className={styles.dateWrapper}>
              <div className={styles.dateContainer}>
                <span className={styles.dateLabel}>
                  {t('order_period_start_date')}
                </span>
                <DatePicker
                  name="start_date"
                  className="datepicker"
                  format="DD.MM.YYYY"
                  value={
                    formik.values.dates.start_date
                      ? dayjs(formik.values.dates.start_date)
                      : null
                  }
                  onChange={date => {
                    formik.setFieldValue(
                      'dates.start_date',
                      date ? dayjs(date).format('YYYY-MM-DD') : null
                    );
                  }}
                  placeholder={t('date')}
                  disabledDate={(current: Dayjs) =>
                    formik.values.dates.end_date
                      ? current &&
                        current >
                          dayjs(formik.values.dates.end_date).endOf('day')
                      : false
                  }
                  onBlur={() =>
                    formik.setFieldTouched('dates.start_date', true)
                  }
                />
              </div>
              <div className={styles.dateContainer}>
                <span className={styles.dateLabel}>
                  {t('order_period_end_date')}
                </span>
                <DatePicker
                  name="end_date"
                  className="datepicker"
                  format="DD.MM.YYYY"
                  value={
                    formik.values.dates.end_date
                      ? dayjs(formik.values.dates.end_date)
                      : null
                  }
                  onChange={date => {
                    formik.setFieldValue(
                      'dates.end_date',
                      date ? dayjs(date).format('YYYY-MM-DD') : null
                    );
                  }}
                  placeholder={t('date')}
                  disabledDate={(current: Dayjs) =>
                    formik.values.dates.start_date
                      ? current &&
                        current <
                          dayjs(formik.values.dates.start_date).startOf('day')
                      : false
                  }
                  onBlur={() => formik.setFieldTouched('dates.end_date', true)}
                />
              </div>
            </div>
            <div>
              <CustomSelect
                label={t('cancellation_reason')}
                value={formik.values.cancel_orders.cancel_by}
                className={styles.topSelect}
                options={[
                  {
                    id: 1,
                    display_name: `${t('cancelled_by_user')}`,
                    name: 'admin',
                  },
                  {
                    id: 2,
                    display_name: `${t('cancelled_by_system')}`,
                    name: 'system',
                  },
                  {
                    id: 3,
                    display_name: `${t('cancelled_by_administrator')}`,
                    name: 'admin',
                  },
                ]}
                placeholder={t('select_cancellation_reason')}
                onChange={value =>
                  formik.setFieldValue('cancel_orders.cancel_by', value)
                }
              />
            </div>
          </div>
          <div className={styles.operators}>
            <div className={styles.operatorWrapper}>
              <span className={styles.operatorsLabel}>
                {t('number_of_cancelled_dishes')}
              </span>
              <div className={styles.operatorLine}>
                <CustomSelect
                  value={formik.values.cancel_wares.condition}
                  options={[
                    { id: 1, display_name: `${t('more')}`, name: '>' },
                    { id: 2, display_name: `${t('less')}`, name: '<' },
                    { id: 3, display_name: `${t('equal')}`, name: '=' },
                  ]}
                  placeholder={t('select_operator')}
                  onChange={value =>
                    formik.setFieldValue('cancel_wares.condition', value)
                  }
                />
                <InputBar
                  value={formik.values.cancel_wares.count}
                  name="cancel_wares.count"
                  onChange={formik.handleChange}
                  classNameInputWrapper={styles.input}
                  placeholder={t('enter_the_number')}
                  label=""
                />
              </div>
            </div>
            <div className={styles.operatorWrapper}>
              <span className={styles.operatorsLabel}>
                {t('number_of_cancelled_orders')}
              </span>
              <div className={styles.operatorLine}>
                <CustomSelect
                  value={formik.values.cancel_orders.condition}
                  options={[
                    { id: 1, display_name: `${t('more')}`, name: '>' },
                    { id: 2, display_name: `${t('less')}`, name: '<' },
                    { id: 3, display_name: `${t('equal')}`, name: '=' },
                  ]}
                  placeholder={t('select_operator')}
                  onChange={value =>
                    formik.setFieldValue('cancel_orders.condition', value)
                  }
                />
                <InputBar
                  value={formik.values.cancel_orders.count}
                  name="cancel_orders.count"
                  onChange={formik.handleChange}
                  classNameInputWrapper={styles.input}
                  placeholder={t('enter_the_number')}
                  label=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            text={t('apply_filter')}
            type="submit"
            className={styles.button}
          />
          {selectedUsers.length !== 0 && (
            <Button
              text={t('change_status_of_selected_employees')}
              type="button"
              onClick={setIsStatusUsersModalOpen}
              className={cn(styles.button, styles.statusButton)}
            />
          )}
        </div>
      </form>
    </div>
  );
};
