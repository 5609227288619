import { Routes, Route, useLocation, redirect } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Accommodations from './pages/Accommodations';
import EditAccommodation from './pages/EditAccommodation';
import CreateAccommodation from './pages/CreateAccommodation';
import Food from './pages/Food';
import CreateFood from './pages/CreateFood';
import EditFood from './pages/EditFood';
import EditDish from './pages/EditDish';
import Social from './pages/Social';
import Users from './pages/Users';
import CreateUser from './pages/CreateUser';
import Feedbacks from './pages/Feedbacks';
import EditUser from './pages/EditUser';
import FeedbackProccesing from './pages/FeedbackProccesing';
import FeedbackComment from './pages/FeedbackComment';
import CreateAnnouncement from './pages/CreateAnnouncement';
import CreateNotification from './pages/CreateNotification';
import EditNotification from './pages/EditNotification';
import EditAnnouncement from './pages/EditAnnouncement';
import Announcements from './pages/Announcements';
import Notifications from './pages/Notifications';
import Requests from './pages/Requests';
import Request from './pages/Request';
import Login from './pages/Login';
import { ToastContainer } from 'react-toastify';
import GeneratorQr from './pages/GeneratorQr';
import CreateQr from './pages/CreateQr';
import CreateFoodPerson from './pages/CreateFoodPerson/CreateFoodPerson';
import EditFoodPerson from './pages/EditFoodPerson/EditFoodPerson';
import { Transactions } from './pages/Transactions/Transactions';
import { Transaction } from './pages/Transaction/Transaction';
import { Order } from './pages/Order/Order';
import CreateSocial from './pages/CreateSocial/CreateSocial';
import MobileApplicationRedirect from './pages/MobileApplicationRedirect';
import DashboardPage from './pages/DashboardPage';
import NotFound from './pages/NotFound';
import Questionnaires from './pages/Questionnaires';
import CreateQuestionnarie from './pages/CreateQuestionnarie';
import Assets from './pages/Assets';
import CreateAsset from './pages/CreateAsset/CreateAsset';
import EditAsset from './pages/EditAsset/EditAsset';
import { checkIfFoodAdmin, checkIfUserSuperAdmin } from './utils/getUserRole';
import { AuthenticationContext } from './components/Authentication/AuthenticationProvider';
import { useContext, useEffect } from 'react';
import EditQuestionnarie from './pages/EditQuestionnarie/EditQuestionnarie';
import QuestionnarieStatsPage from './pages/QuestionnarieStatsPage/QuestionnarieStatsPage';
import CompleteQuestionnariePage from './pages/CompleteQuestionnariePage/CompleteQuestionnariePage';
import QuestionAccordion from './pages/FaqMobile/QuestionAccordion/QuestionAccordion';
import FinishQuestionnarie from './pages/FinishQuestionnarie/FinishQuestionnarie';
import QuestionnarieError from './pages/QuestionnarieError/QuestionnarieError';
import { toastUpload } from './utils/useToastUpload';
import FaqMobile from './pages/FaqMobile/FaqMobile';
import Settings from './pages/Settings';

// FireBase
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import QuestionsList from './pages/FaqMobile/QuestionsList/QuestionsList';
import FaqAuth from './pages/FaqMobile/FaqAuth/FaqAuth';
import FaqApp from './pages/FaqMobile/FaqApp/FaqApp';
import FaqPropose from './pages/FaqMobile/FaqPropose/FaqPropose';
import Layout from './components/Shared/Layout';

import './App.scss';
import Events from './pages/Events/Events';
import EventsBoard from './components/Events/EventsBoard/EventsBoard';

const env = import.meta.env;

const firebaseConfig = {
  apiKey: env.VITE_FIREBASE_API_KEY,
  authDomain: env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.VITE_FIREBASE_APP_ID,
  measurementId: env.VITE_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const { user } = useContext(AuthenticationContext);
  const isAssetsRoutesExist = checkIfUserSuperAdmin(user?.data.role_name);
  const isFoodAdminAccess = checkIfFoodAdmin(user?.data.role_name);
  const uploadId = localStorage.getItem('uploadId');
  const location = useLocation();

  if (uploadId) {
    toastUpload(uploadId);
  }

  if (isFoodAdminAccess) {
    if (location.pathname === '/') {
      redirect('/transactions');
    }
  }

  return (
    <div className="App">
      <ToastContainer icon={false} limit={3} />
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          {!isFoodAdminAccess ? (
            <>
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <DashboardPage />
                  </PrivateRoute>
                }
              />
              {isAssetsRoutesExist && (
                <>
                  <Route
                    path="assets"
                    element={
                      <PrivateRoute>
                        <Assets />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="assets/create"
                    element={
                      <PrivateRoute>
                        <CreateAsset />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="assets/:assetID/edit"
                    element={
                      <PrivateRoute>
                        <EditAsset />
                      </PrivateRoute>
                    }
                  />
                </>
              )}
              <Route
                path="accommodations"
                element={
                  <PrivateRoute>
                    <Accommodations />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/accommodations/:accommodationID"
                element={
                  <PrivateRoute>
                    <EditAccommodation />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/accommodations/:accommodationID/calendar"
                element={
                  <PrivateRoute>
                    <EditAccommodation />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/accommodations/:accommodationID/info"
                element={
                  <PrivateRoute>
                    <EditAccommodation />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/accommodations/create"
                element={
                  <PrivateRoute>
                    <CreateAccommodation />
                  </PrivateRoute>
                }
              />
              <Route
                path="food"
                element={
                  <PrivateRoute>
                    <Food />
                  </PrivateRoute>
                }
              />
              <Route
                path="food/create"
                element={
                  <PrivateRoute>
                    <CreateFood />
                  </PrivateRoute>
                }
              />

              <Route
                path="food/:foodID"
                element={
                  <PrivateRoute>
                    <EditFood />
                  </PrivateRoute>
                }
              />

              <Route
                path="food/:foodID/category"
                element={
                  <PrivateRoute>
                    <EditFood />
                  </PrivateRoute>
                }
              />
              <Route
                path="food/:foodID/info"
                element={
                  <PrivateRoute>
                    <EditFood />
                  </PrivateRoute>
                }
              />
              <Route
                path="food/:foodID/menu"
                element={
                  <PrivateRoute>
                    <EditFood />
                  </PrivateRoute>
                }
              />
              <Route
                path="food/:foodID/types"
                element={
                  <PrivateRoute>
                    <EditFood />
                  </PrivateRoute>
                }
              />
              <Route
                path="food/:foodID/persons"
                element={
                  <PrivateRoute>
                    <EditFood />
                  </PrivateRoute>
                }
              />
              <Route
                path="food/:foodID/current"
                element={
                  <PrivateRoute>
                    <EditFood />
                  </PrivateRoute>
                }
              />
              <Route
                path="food/:foodID/orders"
                element={
                  <PrivateRoute>
                    <EditFood />
                  </PrivateRoute>
                }
              />

              <Route
                path="food/:foodID/orders/:transactionID"
                element={
                  <PrivateRoute>
                    <Transaction />
                  </PrivateRoute>
                }
              />

              <Route
                path="food/:foodID/persons/create"
                element={
                  <PrivateRoute>
                    <CreateFoodPerson />
                  </PrivateRoute>
                }
              />

              <Route
                path="food/:foodID/persons/:personID"
                element={
                  <PrivateRoute>
                    <EditFoodPerson />
                  </PrivateRoute>
                }
              />

              <Route
                path="food/:foodID/menu/:dishID"
                element={
                  <PrivateRoute>
                    <EditDish />
                  </PrivateRoute>
                }
              />

              <Route
                path="social"
                element={
                  <PrivateRoute>
                    <Social />
                  </PrivateRoute>
                }
              />

              <Route
                path="social/actual"
                element={
                  <PrivateRoute>
                    <Social />
                  </PrivateRoute>
                }
              />

              <Route
                path="social/archive"
                element={
                  <PrivateRoute>
                    <Social />
                  </PrivateRoute>
                }
              />

              <Route
                path="social/create"
                element={
                  <PrivateRoute>
                    <CreateSocial />
                  </PrivateRoute>
                }
              />

              <Route
                path="user"
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="user/create"
                element={
                  <PrivateRoute>
                    <CreateUser />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="user/:userID"
                element={
                  <PrivateRoute>
                    <EditUser />
                  </PrivateRoute>
                }
              />
              <Route
                path="feedback"
                element={
                  <PrivateRoute>
                    <Feedbacks />
                  </PrivateRoute>
                }
              />
              <Route
                path="feedback/:feedbackID"
                element={
                  <PrivateRoute>
                    <FeedbackProccesing />
                  </PrivateRoute>
                }
              />
              <Route
                path="feedback/:feedbackID/comment"
                element={
                  <PrivateRoute>
                    <FeedbackComment />
                  </PrivateRoute>
                }
              />
              <Route
                path="announcements"
                element={
                  <PrivateRoute>
                    <Announcements />
                  </PrivateRoute>
                }
              />
              <Route
                path="announcements/create"
                element={
                  <PrivateRoute>
                    <CreateAnnouncement />
                  </PrivateRoute>
                }
              />
              <Route
                path="notifications/create"
                element={
                  <PrivateRoute>
                    <CreateNotification />
                  </PrivateRoute>
                }
              />
              <Route
                path="notifications/:notificationID"
                element={
                  <PrivateRoute>
                    <EditNotification />
                  </PrivateRoute>
                }
              />
              <Route
                path="announcements/:announcementID"
                element={
                  <PrivateRoute>
                    <EditAnnouncement />
                  </PrivateRoute>
                }
              />
              <Route
                path="notifications"
                element={
                  <PrivateRoute>
                    <Notifications />
                  </PrivateRoute>
                }
              />
              <Route
                path="requests"
                element={
                  <PrivateRoute>
                    <Requests />
                  </PrivateRoute>
                }
              />
              <Route
                path="requests/:requestID"
                element={
                  <PrivateRoute>
                    <Request />
                  </PrivateRoute>
                }
              />

              <Route
                path="/qr"
                element={
                  <PrivateRoute>
                    <GeneratorQr />
                  </PrivateRoute>
                }
              />
              <Route
                path="/qr/create"
                element={
                  <PrivateRoute>
                    <CreateQr />
                  </PrivateRoute>
                }
              />
            </>
          ) : null}

          <Route
            path="/transactions"
            element={
              <PrivateRoute>
                <Transactions />
              </PrivateRoute>
            }
          />

          <Route
            path="/transactions/:transactionID"
            element={
              <PrivateRoute>
                <Transaction />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/events"
            element={
              <PrivateRoute>
                <Events />
              </PrivateRoute>
            }
          />

          {!isFoodAdminAccess ? (
            <>
              <Route
                path="/orders/:orderID"
                element={
                  <PrivateRoute>
                    <Order />
                  </PrivateRoute>
                }
              />

              <Route
                path="/questionnaires"
                element={
                  <PrivateRoute>
                    <Questionnaires />
                  </PrivateRoute>
                }
              />

              <Route
                path="/questionnaires/create"
                element={
                  <PrivateRoute>
                    <CreateQuestionnarie />
                  </PrivateRoute>
                }
              />
              <Route
                path="/questionnaires/:questionnaireID"
                element={
                  <PrivateRoute>
                    <EditQuestionnarie />
                  </PrivateRoute>
                }
              />

              <Route
                path="/questionnaires/:questionnaireID/results"
                element={
                  <PrivateRoute>
                    <QuestionnarieStatsPage />
                  </PrivateRoute>
                }
              />
            </>
          ) : null}
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
        </Route>

        <>
          <Route
            exact
            path="/complete-questionnarie"
            element={<CompleteQuestionnariePage />}
          />

          <Route
            exact
            path="/complete-questionnarie/user-error"
            element={<QuestionnarieError />}
          />
          <Route
            exact
            path="/complete-questionnarie/thank-you"
            element={<FinishQuestionnarie />}
          />
        </>

        <Route path="/login" element={<Login />} />

        <Route
          path="/mobile-application-store"
          element={<MobileApplicationRedirect />}
        />

        <Route path="/faq" element={<FaqMobile />}>
          <Route index element={<QuestionsList />} />
          <Route path="auth" element={<FaqAuth />} />
          <Route path="/faq/app" element={<FaqApp />} />
          <Route path="/faq/add" element={<FaqPropose />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
