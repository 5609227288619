import { useTranslation } from 'react-i18next';
import styles from './QuestionnarieError.module.scss';

const QuestionnarieError = () => {
  const { t } = useTranslation();

  return (
    <span className={styles.questionnarieError}>
      {t('questionnaire_already_completed_message')}
    </span>
  );
};

export default QuestionnarieError;
