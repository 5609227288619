import { instance } from '~/utils/api/api';
import { Dayjs } from 'dayjs';
import { downloadFile } from './downloadFile';

export async function downloadReportFile(
  reportPath: string,
  reportName: string,
  startDate: Dayjs | null,
  endDate: Dayjs | null,
  isAllAccommodations?: boolean
) {
  const response = await instance.get(`admin/${reportPath}`, {
    params: {
      ...(reportPath.includes('food-wares-export')
        ? {
            dates: [
              startDate?.format('YYYY-MM-DD 00:00:01'),
              endDate?.format('YYYY-MM-DD 00:00:01'),
            ],
          }
        : { start_date: startDate, end_date: endDate }),
      ...(isAllAccommodations ? { accommodation_all: 1 } : {}),
    },
    responseType: 'blob',
  });

  downloadFile(response.data, 'text/xlsx', `${reportName}.xlsx`);
}

export async function downloadMultipleAccommodationReportFile(
  reportPath: string,
  reportName: string,
  accommodationIDs: number[],
  startDate: Dayjs | null,
  endDate: Dayjs | null
) {
  const response = await instance.get(`admin/${reportPath}`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      accommodation_ids: accommodationIDs,
    },
    responseType: 'blob',
  });

  downloadFile(response.data, 'text/xlsx', `${reportName}.xlsx`);
}

export async function downloadAllAccommodationReportFile(
  reportPath: string,
  reportName: string,
  accommodationIDs: number[],
  startDate: Dayjs | null,
  endDate: Dayjs | null
) {
  const response = await instance.get(`admin/${reportPath}`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      accommodation_ids: accommodationIDs,
    },
    responseType: 'blob',
  });

  downloadFile(response.data, 'text/xlsx', `${reportName}.xlsx`);
}
