import cn from 'classnames';

import styles from './EventType.module.scss';

export default function EventType({
  eventType,
  eventName,
}: {
  eventType: string;
  eventName: string;
}) {
  switch (eventType) {
    case 'create': {
      return (
        <div className={cn(styles.eventType, styles.eventTypeCreated)}>
          {eventName}
        </div>
      );
    }
    case 'update': {
      return (
        <div className={cn(styles.eventType, styles.eventTypeUpdated)}>
          {eventName}
        </div>
      );
    }
    case 'delete': {
      return (
        <div className={cn(styles.eventType, styles.eventTypeDeleted)}>
          {eventName}
        </div>
      );
    }
    case 'other': {
      return (
        <div className={cn(styles.eventType, styles.eventTypeOther)}>
          {eventName}
        </div>
      );
    }
    default: {
      return (
        <div className={cn(styles.eventType, styles.eventTypeOther)}>
          {eventName}
        </div>
      );
    }
  }
}
