import Bed from '~/assets/svg/newSvg/aside/bed.svg?react';
import Users from '~/assets/svg/newSvg/aside/users.svg?react';
import Kitchen from '~/assets/svg/newSvg/aside/kitchen.svg?react';
import Tools from '~/assets/svg/newSvg/aside/tools.svg?react';
import Chat from '~/assets/svg/newSvg/aside/chat.svg?react';
import Lightning from '~/assets/svg/newSvg/aside/lightning.svg?react';
import Notification from '~/assets/svg/newSvg/aside/notification.svg?react';
import Qr from '~/assets/svg/newSvg/aside/qr.svg?react';
import Transactions from '~/assets/svg/newSvg/aside/transactions.svg?react';
import Case from '~/assets/svg/newSvg/aside/case.svg?react';
import Questionnaire from '~/assets/svg/newSvg/aside/questionnaires.svg?react';
import Settings from '~/assets/svg/newSvg/aside/setting.svg?react';
import Events from '~/assets/svg/events/events.svg?react';

export const asideBarList = [
  { id: 1, path: '/assets', text: 'assets', icon: <Case /> },
  { id: 2, path: '/accommodations', text: 'accommodations', icon: <Bed /> },
  { id: 3, path: '/food', text: 'nutrition', icon: <Kitchen /> },
  { id: 4, path: '/transactions', text: 'orders', icon: <Transactions /> },
  { id: 5, path: '/user', text: 'employees', icon: <Users /> },
  { id: 6, path: '/requests', text: 'applications', icon: <Tools /> },
  { id: 7, path: '/feedback', text: 'feedback', icon: <Chat /> },
  { id: 8, path: '/announcements', text: 'announcements', icon: <Lightning /> },
  {
    id: 9,
    path: '/questionnaires',
    text: 'questionnaires',
    icon: <Questionnaire />,
  },
  {
    id: 10,
    path: '/events',
    text: 'events',
    icon: <Events />,
  },
];

export const asideBarListSetting = [
  {
    id: 0,
    path: '/notifications',
    text: 'notifications',
    icon: <Notification />,
  },
  {
    id: 1,
    path: '/settings',
    text: 'settings',
    icon: <Settings />,
  },
  {
    id: 2,
    path: '/qr',
    text: 'qr',
    icon: <Qr />,
  },
];
