import { NavLink } from 'react-router-dom';
import ArrowRight from '~/assets/svg/newSvg/arrow-right.svg?react';
import styles from './AsideList.module.scss';
import { ReactNode, useContext, useEffect } from 'react';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfFoodAdmin, checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useToastError } from '~/utils/useToastError';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import Notifications from '../../Header/NotificationsContainer/Notifications/Notifications';

interface AsideItem {
  id: number;
  path: string;
  text: string;
  icon: ReactNode;
}

interface AsideListProps {
  isExpanded: boolean;
  list: AsideItem[];
}

const AsideList = ({ list, isExpanded }: AsideListProps): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const isSuperAdminAccess = checkIfUserSuperAdmin(user?.data.role_name);
  const isFoodAdminAccess = checkIfFoodAdmin(user?.data.role_name);

  const filteredList = list.filter(item => {
    if (isFoodAdminAccess) {
      return item.path === '/settings';
    }
    return isSuperAdminAccess || item.path !== '/assets';
  });

  const { t } = useTranslation();
  const toastError = useToastError();

  const { data: notifications, refetch } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/new-records');
      return data;
    },
    queryKey: ['notifications-count'],
    onError: error => {
      toastError(error);
    },
  });

  useEffect(() => {
    if (isFoodAdminAccess) {
      const interval = setInterval(() => {
        refetch();
      }, 60000);
      () => clearInterval(interval);
    }
  }, []);

  return (
    <ul
      className={cn(
        styles.aside__list,
        isExpanded ? styles.aside__listExpanded : undefined
      )}
    >
      {filteredList.map(({ id, path, text, icon }) => {
        return (
          <li key={id} className={styles.aside__item}>
            <NavLink
              to={path}
              className={({ isActive }) =>
                `${isActive ? styles.active : ''} ${styles.aside__link}`
              }
            >
              <span className={styles.aside__icon}>{icon}</span>

              <div className={styles.aside__text}>
                {isExpanded ? <span>{t(text)}</span> : ''}
                <div className={styles.asideRight}>
                  {path === '/requests' && !isFoodAdminAccess ? (
                    <Notifications count={notifications?.new_tickets_count} />
                  ) : (
                    ''
                  )}
                  {path === '/transactions' && !isFoodAdminAccess ? (
                    <Notifications count={notifications?.new_orders_count} />
                  ) : (
                    ''
                  )}
                  {path === '/feedback' && !isFoodAdminAccess ? (
                    <Notifications count={notifications?.new_feedbacks_count} />
                  ) : (
                    ''
                  )}
                  {isExpanded ? <ArrowRight className={styles.arrow} /> : ''}
                </div>
              </div>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export default AsideList;
