import EventsBoardFilters from './EventsBoardFilters/EventsBoardFilters';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { Board } from '~/components/Shared/Layout/Board/Board';
import FilterIcon from '~/assets/svg/newSvg/filter.svg?react';
import { useMemo, useReducer, useState } from 'react';
import { IconButton, Pagination } from '~/ui';
import EventsTable from './EventsTable/EventsTable';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import styles from './EventsBoard.module.scss';
import { instance } from '~/utils/api/api';
import { Dayjs } from 'dayjs';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { useDebounce } from '@uidotdev/usehooks';

interface SelectedPage {
  selected: number;
}

export interface IFilterValues {
  dates: [Dayjs | null, Dayjs | null];
  initiator: { id: number; name: string }[];
  event: { id: number; name: string }[];
  type: { id: number; name: string }[];
}

const initialState: IFilterValues = {
  dates: [null, null],
  initiator: [],
  event: [],
  type: [],
};

const initialFilters: IFilterValues = {
  dates: [null, null],
  initiator: [],
  event: [],
  type: [],
};

const handleChangeFilters = (
  state: IFilterValues,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case 'CHANGE_TO_INITIAL': {
      return initialState;
    }
    case 'CHANGE_DATES': {
      return {
        ...state,
        dates: action.payload,
      };
    }
    case 'CHANGE_INITIATOR': {
      return {
        ...state,
        initiator: action.payload,
      };
    }
    case 'CHANGE_EVENT': {
      return {
        ...state,
        event: action.payload,
      };
    }
    case 'CHANGE_TYPE': {
      return {
        ...state,
        type: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const EventsBoard = () => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [page, setPage] = useState<number>();
  const [filters, dispatch] = useReducer(handleChangeFilters, initialFilters);
  const [perPage, setPerPage] = useState(5);
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const { t } = useTranslation();

  const params = useMemo(() => {
    return {
      page,
      field: sort.field,
      sort: sort.direction,
      perPage,
      user_ids: filters.initiator?.map((user: { id: number }) => user.id),
      events: filters.event?.map((event: { event: string }) => event.event),
      type_ids: filters.type?.map(
        (type: { id: number; name: string }) => type.id
      ),
      dates: filters.dates,
    };
  }, [
    filters.dates,
    filters.event,
    filters.initiator,
    filters.type,
    page,
    perPage,
    sort.field,
    sort.direction,
  ]);

  const debouncedParams = useDebounce(params, 500);

  const handleToggleFilters = () => {
    setIsFiltersOpen(prevState => !prevState);
  };

  const { data: events } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/event-logs', { params });
      return data;
    },
    queryKey: ['events', debouncedParams],
    keepPreviousData: true,
  });

  return (
    <Board>
      <div className={styles.boardOptions}>
        <TableSelect perPage={perPage} setPerPage={setPerPage} />
        <IconButton
          className={styles.filtersButton}
          onClick={handleToggleFilters}
        >
          <FilterIcon /> {isFiltersOpen ? t('hide_filters') : t('show_filters')}
        </IconButton>
      </div>
      {isFiltersOpen && (
        <div className={styles.filtersWrapper}>
          <EventsBoardFilters filters={filters} dispatch={dispatch} />
        </div>
      )}
      <div className={styles.tableWrapper}>
        <EventsTable sort={sort} setSort={setSort} events={events?.data} />
      </div>
      {events?.data.length ? (
        <div className={styles.foodTablePagination}>
          <TableAmountPage
            firstRow={events?.from}
            lastRow={events?.to}
            total={events?.total}
          />
          <Pagination
            pageCount={events?.last_page}
            onPageChange={(selectedPage: SelectedPage) => {
              setPage(selectedPage.selected + 1);
            }}
          />
        </div>
      ) : null}
    </Board>
  );
};

export default EventsBoard;
