import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';
import EventUserSelect from '../EventUserSelect/EventUserSelect';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { IFilterValues } from '../EventsBoard';
import { useTranslation } from 'react-i18next';
import { Button, BUTTON_STYLES } from '~/ui';
import { instance } from '~/utils/api/api';
import { Dispatch, useState } from 'react';
import { DatePicker } from 'antd';
import cn from 'classnames';

import styles from './EventsBoardFilters.module.scss';

interface IEventsBoardFiltersProps {
  filters: IFilterValues;
  dispatch: Dispatch<{ type: string; payload: any }>;
}

const EventsBoardFilters = ({
  filters,
  dispatch,
}: IEventsBoardFiltersProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const { t } = useTranslation();
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const { data: filterTypes } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/event-logs-types');
      return data;
    },
    queryKey: ['filter-types'],
  });

  const { data: eventTypes } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/event-logs-events');
      return data;
    },
    queryKey: ['filter-events'],
  });

  const { data: users, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/users', {
        params: {
          query: debouncedSearchValue,
          field: '',
          sort: '',
          perPage: 100,
          page: 1,
        },
      });
      return data.users.data;
    },
    queryKey: ['users', debouncedSearchValue],
  });

  return (
    <div className={styles.filters}>
      <div className={styles.filterGrid}>
        <div className={styles.dateWrapper}>
          <span className={styles.dateTitle}>{t('event_date')}</span>
          <DatePicker.RangePicker
            className={styles.select}
            placeholder={[t('date_start_validate'), t('date_end_validate')]}
            onChange={dates => {
              if (dates && dates[0] && dates[1]) {
                dispatch({
                  type: 'CHANGE_DATES',
                  payload: [dates[0].startOf('day'), dates[1].endOf('day')],
                });
              } else {
                dispatch({
                  type: 'CHANGE_DATES',
                  payload: [null, null],
                });
              }
            }}
            value={filters.dates}
          />
        </div>
        <EventUserSelect
          label={t('initiator')}
          searchValue={searchValue}
          isLoading={isLoading}
          onSearchChange={setSearchValue}
          className={styles.select}
          onChange={value =>
            dispatch({ type: 'CHANGE_INITIATOR', payload: value })
          }
          value={filters.initiator}
          multiselect
          isSearchEnabled
          options={users || []}
          placeholder={t('initiator_placeholder')}
        />
        <CustomSelect
          label={t('event')}
          className={styles.select}
          onChange={value => dispatch({ type: 'CHANGE_TYPE', payload: value })}
          value={filters.type}
          multiselect
          options={filterTypes || []}
          placeholder={t('event_placeholder')}
        />
        <CustomSelect
          label={t('event_type')}
          className={styles.select}
          onChange={value => dispatch({ type: 'CHANGE_EVENT', payload: value })}
          value={filters.event}
          multiselect
          options={eventTypes || []}
          placeholder={t('event_type_placeholder')}
        />
      </div>
      <div className={styles.controls}>
        <Button
          onClick={() => dispatch({ type: 'CHANGE_TO_INITIAL', payload: '' })}
          className={cn(styles.button, styles.buttonCancel)}
          buttonStyle={BUTTON_STYLES.EMPTY}
          text={t('reset_filters')}
        />
      </div>
    </div>
  );
};

export default EventsBoardFilters;
