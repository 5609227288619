import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import styles from './EventsTable.module.scss';
import EventType from './EventType/EventType';
import dayjs from 'dayjs';

interface IEventsTableProps {
  events: any[];
  sort: any;
  setSort: ({ field, direction }: any) => void;
}

const EventsTable = ({ events, sort, setSort }: IEventsTableProps) => {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead field="type_id">{t('event')}</TableCellHead>
        <TableCellHead field="user_id">{t('initiator')}</TableCellHead>
        <TableCellHead field="created_at">{t('event_date')}</TableCellHead>
        <TableCellHead field="event">{t('event_type')}</TableCellHead>
      </TableHead>
      <TableBody>
        {events?.map(event => {
          return (
            <TableRow key={event?.id}>
              <TableCell>{event?.type?.display_name}</TableCell>
              <TableCell className={styles.nameCell}>
                {event?.user?.code || '-'}
              </TableCell>
              <TableCell>
                {dayjs(event?.created_at).format('DD.MM.YYYY HH:mm:ss')}
              </TableCell>
              <TableCell className={styles.typeCell}>
                <EventType
                  eventType={event?.event}
                  eventName={event?.event_name}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default EventsTable;
